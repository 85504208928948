<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
          w-reset
          mb-sm-2
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.paymentBonusP") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
          w-reset
          w-reset
        "
      >
        <export-excel
          v-can="'patients.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Бонусные Платежи"
          name="Бонусные Платежи.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete">
            Excel
          </el-button>
        </export-excel>
        <!-- <el-button
          v-can="'payment_types.create'"
          size="mini"
          @click="drawerCreate = true"
          icon="el-icon-upload2"
        >
          {{ $t("message.create") }}
        </el-button> -->
        <crm-column-settings
          :columns="columns"
          :modelName="'paymentBonus'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>

    <table class="table table-bordered table-hover" v-loading="loadingData">
      <thead>
        <tr>
          <th class="w50p" v-if="columns.id.show">
            {{ columns.id.title }}
          </th>

          <th v-if="columns.part_id.show">
            {{ columns.part_id.title }}
          </th>

          <th v-if="columns.name.show">
            {{ columns.name.title }}
          </th>

          <th v-if="columns.date.show">
            {{ columns.date.title }}
          </th>

          <th v-if="columns.payment_type_id.show">
            {{ columns.payment_type_id.title }}
          </th>

          <th v-if="columns.price.show">
            {{ columns.price.title }}
          </th>

          <th v-if="columns.comment.show">
            {{ columns.comment.title }}
          </th>

          <th v-if="columns.created_at.show">
            {{ columns.created_at.title }}
          </th>
          <th v-if="columns.updated_at.show">
            {{ columns.updated_at.title }}
          </th>

          <th v-if="columns.settings.show">
            {{ columns.settings.title }}
          </th>
        </tr>

        <tr class="filter_sorche">
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
              class="id_input"
            ></el-input>
          </th>

          <th v-if="columns.part_id.show">
            <template>
              <el-select v-model="filterForm.part" clearable :placeholder="columns.part_id.title" size="mini">
                <el-option :label="$t('message.partner_clinic')" value="partner_clinics"></el-option>
                <el-option :label="$t('message.partner_doctor')" value="partner_doctors"></el-option>
                <el-option  label="Наш доктор" value="doctors"></el-option>
              </el-select>
            </template>
          </th>

          <th v-if="columns.name.show">
            <el-input
              clearable
              size="mini"
              v-model="filterForm.name"
              :placeholder="columns.name.title"
            ></el-input>
          </th>

          <th v-if="columns.date.show">
            <el-date-picker
              :placeholder="columns.date.title"
              v-model="filterForm.date"
              size="mini"
            >
            </el-date-picker>
          </th>

          <th v-if="columns.payment_type_id.show">
            <crm-payment-types v-model="filterForm.payment_type_id" size="mini" :plc="columns.payment_type_id.title"></crm-payment-types>
          </th>

          <th v-if="columns.price.show"></th>

          <th v-if="columns.comment.show"></th>

          <th v-if="columns.created_at.show">
            <el-date-picker
              :placeholder="columns.created_at.title"
              v-model="filterForm.created_at"
              size="mini"
            >
            </el-date-picker>
          </th>

          <th v-if="columns.updated_at.show">
            <el-date-picker
              :placeholder="columns.updated_at.title"
              v-model="filterForm.updated_at"
              size="mini"
            >
            </el-date-picker>
          </th>

          <th class="settinW" v-if="columns.settings.show"></th>
        </tr>
      </thead>
 
      <transition-group name="flip-list" tag="tbody">
        <tr
          v-for="payment_bonus in list"
          :key="payment_bonus.id"
          class="cursor-pointer"
        >
          <td v-if="columns.id.show">{{ payment_bonus.id }}</td>

          <td v-if="columns.part_id.show">
            {{ $t('message.'+payment_bonus.part_name) }}
          </td>

          <td v-if="columns.name.show">
            {{ payment_bonus.part_name == 'Наш доктор' ? payment_bonus.name.surname+' '+payment_bonus.name.name: payment_bonus.name.name }}
          </td>

          <td v-if="columns.date.show">
            {{ payment_bonus.date }}
          </td>

          <td v-if="columns.payment_type_id.show">
            {{ payment_bonus.payment_type.name }}
          </td>

          <td v-if="columns.part_id.show">
            {{ payment_bonus.price | formatMoney }}
          </td>

          <td v-if="columns.comment.show">
            {{ payment_bonus.comment }}
          </td>

          <td v-if="columns.created_at.show">
            {{ payment_bonus.created_at }}
          </td>

          <td v-if="columns.updated_at.show">
            {{ payment_bonus.updated_at }}
          </td>

          <td v-if="columns.settings.show" class="settings-td">
            <el-button
                round
                @click="edit(payment_bonus)"
                size="mini"
                type="primary"
                icon="el-icon-view"
                >{{ $t("message.show_0") }}</el-button
              >
            <!-- <crm-setting-dropdown
              :model="payment_bonus"
              name="paymentTypes"
              :actions="actions"
              @edit="edit"
              @delete="destroy"
            >
            </crm-setting-dropdown> -->
          </td>
        </tr>
      </transition-group>
    </table>
    <el-drawer
      :visible.sync="drawerCreate"
      size="95%" :wrapperClosable="false"
      :drawer="drawerCreate"
      @close="reloadIfChanged"
      class="mytab_IdP"
    >
      <div>
        <crm-create
          :reloadModel="reopenUpdate"
          @c-close="reloadIfChanged('drawerCreate')"
        ></crm-create>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="drawerUpdate"
      size="70%" :wrapperClosable="false"
      :drawer="drawerUpdate"
      @open="reopenUpdate = true"
      @close="reloadIfChanged('reopenUpdate')"
      @closed="emptyModel"
      class="mytab_IdP"
    >
      <div>
        <crm-show
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="closeDrawer"
        ></crm-show>
      </div>
    </el-drawer>
  </div>
</template>

<script>
// @ is an alias to /src
import CrmUpdate from "./components/crm-update";
import CrmShow from "./components/crm-show";
import CrmCreate from "./components/crm-create";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import CrmPaymentTypes from '../../components/selects/crm-paymentTypes';
export default {
  mixins: [list],
  name: "paymentBonus",
  components: {
    CrmUpdate,
    CrmCreate,
    CrmShow,
    CrmPaymentTypes
    // Pagination,
  },

  data() {
    return {
      appModal: false,
      sarcheBlocks: "",
      drawerUpdate: false,
      drawerCreate: false,
      reopenUpdate: false,
      reopenCreate: false,
      loadingData: false,
      excel_fields: {},
    };
  },
  computed: {
    ...mapGetters({
      list: "paymentBonus/list",
      columns: "paymentBonus/columns",
      pagination: "paymentBonus/pagination",
      filter: "paymentBonus/filter",
      sort: "paymentBonus/sort",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  mounted() {
    this.controlExcelData();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "paymentBonus/index",
      updateSort: "paymentBonus/updateSort",
      updateFilter: "paymentBonus/updateFilter",
      updateColumn: "paymentBonus/updateColumn",
      updatePagination: "paymentBonus/updatePagination",
      editModel: "paymentBonus/show",
      empty: "paymentBonus/empty",
      delete: "paymentBonus/destroy",
      refreshData: "paymentBonus/refreshData",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
          this.fetchData();
        })
        .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {
        "№": "id",
        "Тип Бонус": "part_name",
        ФИО: "name.name",
        Дата: "date",
        "Тип оплаты": "payment_type.name",
        Сумма: "price",
        Комментарий: "comment",
        "Дата создания": "created_at",
        "Дата изменения": "updated_at",
      };
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawerUpdate = true;
        })
        .catch((err) => {});
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => {});
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>

