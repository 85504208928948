<template>
  <div>
    <div class="modal_header">
      <el-row>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              <h5>{{ $t("message.reports") }}</h5>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <export-excel
                class="btn excel_btn"
                :data="model.bonuses"
                :fields="excel_fields"
                @fetch="controlExcelData()"
                worksheet="Отчет"
                name="Отчет.xls"
              >
                <el-button size="mini" icon="el-icon-document-delete">
                  Excel
                </el-button>
              </export-excel>
              <el-button @click="resetForm()">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="modal-doctoe-info">
      <el-row :gutter="10" class="credit_row">
        <el-col :span="6">
          <div class="font__W">{{ $t("message.ifo") }}</div>
          <div>
            {{
              form.part_name == "Наш доктор"
                ? form.name.name + " " + form.name.surname
                : form.name.name
            }}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="font__W">{{ $t("message.quantity") }}</div>
          <div>
            {{ form.bonuses.length }}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="font__W">{{ $t("message.payment_type") }}</div>
          <div>{{ form.payment_type ? form.payment_type.name : "" }}</div>
        </el-col>
        <el-col :span="6">
          <div class="font__W">{{ $t("message.paid") }}</div>
          <div>
            {{
              form.bonuses.length
                ? form.bonuses.reduce(
                    (a, b) => a + parseFloat(b.paid_amount),
                    0
                  )
                : form.price | formatMoney
            }}
          </div>
        </el-col>
      </el-row>

      <div class="text-center float-left sorddata my-3">
        <!-- <el-date-picker
          size="mini"
          v-model="filterForm.orders_from"
          type="date"
          :format="'dd.MM.yyyy'"
          :value-format="'dd.MM.yyyy'"
          :placeholder="$t('message.start_date')"
        ></el-date-picker>
        <el-date-picker
          size="mini"
          v-model="filterForm.orders_to"
          type="date"
          :format="'dd.MM.yyyy'"
          :value-format="'dd.MM.yyyy'"
          :placeholder="$t('message.end_date')"
        ></el-date-picker> -->
      </div>

      <table
        class="table table-hover table-bordered mt-3 table__cerditne__forma"
        v-loading="loadingData"
      >
        <thead>
          <tr>
            <th>id</th>
            <th scope="col">{{ $t("message.ifo") }}</th>
            <th scope="col">{{ $t("message.date_0") }}</th>
            <th scope="col">{{ $t("message.bonus") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(item, index) in form.bonuses"
            :key="index"
            :style="colorPicker(item)"
          >
            <td>{{ item.order_id }}</td>
            <td>
              {{
                item.order && item.order.patient
                  ? item.order.patient.surname +
                    " " +
                    item.order.patient.first_name
                  : ""
              }}
            </td>
            <td>{{ item.order ? item.order.created_at : "" }}</td>

            <td>
              <span class="act_color">{{ item.bonus_amount | formatMoney }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import form from "@/utils/mixins/form";
export default {
  mixins: [form],
  props: ["selected"],
  data() {
    return {
      payment_type_id: null,
      checkedOrders: [],
      dialogVisible: false,
      filterForm: {
        orders_from: "",
        orders_to: "",
      },
      loadingData: false,
      excel_data: [],

      excel_fields: {},
    };
  },
  computed: {
    ...mapGetters({
      model: "paymentBonus/model",
    }),
    total_bonus: function () {
      // let orders = this.form.doctors;
      // let prices = 0;
      // for (let i = 0; i < orders.length; i++) {
      //   const element = orders[i];
      //   prices += parseFloat(element.bonus_amount);
      // }
      // return prices;
    },
  },

  watch: {
    filterForm: {
      handler: function (newVal, oldVal) {
        this.showModel();
      },
      deep: true,
      immediate: true,
    },
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },

  created() {},
  async mounted() {
    this.controlExcelData();
  },
  methods: {
    ...mapActions({
      showModel: "partnerClinicReport/show",
    }),
    colorPicker(item) {
      if (item.bonus_amount == item.paid_amount) {
        return "background-color: rgb(171 255 168 / 62%)";
      } else {
        return "background-color: #ffffff";
      }
    },

    addPayment(item, event) {
      if (event == true) {
        this.checkedOrders.push(item);
      }
      if (event == false) {
        const index = this.checkedOrders.indexOf(item);
        if (index > -1) {
          this.checkedOrders.splice(index, 1);
        }
      }
    },

    controlExcelData() {
      this.excel_fields = {
        id: "order_id",
        
        "И.Ф.О": {
          field: "order",
          callback: (value) => {
            return value.patient.surname + " " + value.patient.first_name;
          },
        },
        Дата: {
          field: "order",
          callback: (value) => {
            return value.created_at;
          },
        },
        // "Не оплачено": {
        //   field: "order",
        //   callback: (value) => {
        //     return value.total_price - value.paid;
        //   },
        // },
        // Оплачено: {
        //   field: "order",
        //   callback: (value) => {
        //     return value.paid;
        //   },
        // },
        // Сумма: {
        //   field: "order",
        //   callback: (value) => {
        //     return value.total_price;
        //   },
        // },
        Бонус: "bonus_amount",
      };
    },
    resetForm() {
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
  },
};
</script>
<style lang="scss">
.modal-doctoe-info {
  margin: 20px 30px;
}
.credit_row {
  margin-left: 10px;
  margin-right: -10px;
  text-align: center;
  background-color: #e4e7ed;
  padding: 10px 0;
  .font__W {
    font-weight: 600;
    font-size: 15px;
  }
  .color_or {
    background: #ffbb58;
    color: #fff;
    padding: 2px 7px;
    border-radius: 10px;
  }
  div {
    font-size: 14px;
  }
}
.act_color {
  background-color: #67c23a;
  color: #fff;
  padding: 2px 7px;
  border-radius: 10px;
}
.not-paid {
  border: 1px solid #dee2e6;
  padding: 1px 4px;
  border-radius: 10px;
  background-color: #fff;
}
.watch--story {
  padding: 7px 8px;
  background-color: #409eff;
  color: #fff;
}
.table__cerditne__forma {
  th {
    font-weight: 600 !important;
    color: #303030 !important;
    font-size: 14px !important;
  }
  th,
  td {
    padding: 0.55rem !important;
  }
  .action_tr {
    background: #ffbb58;
  }
}
</style>